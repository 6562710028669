export const SPECS_SCOPE = 'site-search';

export enum Spec {
  ProductsSorting = 'specs.siteSearch.EnableProductsSorting',
  ShorterSearchTerm = 'specs.siteSearch.EnableShorterSearchTermQuery',
  RenderSeoTags = 'specs.siteSearch.RenderSeoTags',
  AddToCart = 'specs.siteSearch.AddToCart',
  ViewAllButtonDesign = 'specs.siteSearch.ViewAllButtonDesign',
  RemoveLabelOverrideToggle = 'specs.siteSearch.RemoveLabelOverrideToggle',
}
