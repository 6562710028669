import * as React from 'react';

import { ListLayout } from '../ListLayout';
import { EventListItem } from './EventListItem';
import { IListLayoutItemProps, IListLayoutProps } from '../Layout.types';

export const EventList: React.FC<IListLayoutProps> = props => {
  const renderItem = (p: IListLayoutItemProps, key) => {
    return <EventListItem {...p} key={key} />;
  };
  return <ListLayout {...props} renderItem={renderItem} />;
};
