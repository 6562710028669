import * as React from 'react';

import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { ResultsStatus } from '../ResultsStatus';
import { SortControl, ISearchSortProps } from '../SortControl';
import { ITab } from '../../types/types';
import {
  getResultsFoundMessageKey,
  getResultsEmptyMessageKey,
} from '../resultsMessage';

import { st, classes } from './StatusLine.st.css';
import settingsParams from '../../../settingsParams';
import { Settings } from '@wix/search-results-settings-definitions';

type StatusLineProps = {
  sortProps: ISearchSortProps;
  visibleTabsWithTitles: ITab[];
  activeTabIndex?: number;
  settings: Settings;
  searchQuery?: string;
};

const getResultsMessageKey = ({
  count,
  settings,
}: {
  count: number;
  settings: {
    isResultsMessageWithNumber: boolean;
    isResultsMessageWithQuery: boolean;
    isResultsEmptyMessageWithNumber: boolean;
    isResultsEmptyMessageWithQuery: boolean;
  };
}) => {
  const resultsMessageKey =
    count > 0
      ? getResultsFoundMessageKey({
          isWithNumber: settings.isResultsMessageWithNumber,
          isWithQuery: settings.isResultsMessageWithQuery,
        })
      : getResultsEmptyMessageKey({
          isWithNumber: settings.isResultsEmptyMessageWithNumber,
          isWithQuery: settings.isResultsEmptyMessageWithQuery,
        });
  return resultsMessageKey;
};

export const StatusLine: React.FC<StatusLineProps> = ({
  activeTabIndex = 0,
  searchQuery,
  sortProps,
  visibleTabsWithTitles,
}) => {
  const { t } = useTranslation();
  const { isMobile, isEditorX } = useEnvironment();
  const settings = useSettings();

  const count: number =
    (visibleTabsWithTitles.length > 0 &&
      visibleTabsWithTitles[activeTabIndex].count) ||
    0;

  const {
    isResultsEmptyMessageWithNumber,
    isResultsEmptyMessageWithQuery,
    isResultsMessageWithQuery,
    isResultsMessageWithNumber,
  } = settingsParams;

  const messageKey = getResultsMessageKey({
    settings: {
      isResultsEmptyMessageWithNumber: settings.get(
        isResultsEmptyMessageWithNumber,
      ),
      isResultsEmptyMessageWithQuery: settings.get(
        isResultsEmptyMessageWithQuery,
      ),
      isResultsMessageWithQuery: settings.get(isResultsMessageWithQuery),
      isResultsMessageWithNumber: settings.get(isResultsMessageWithNumber),
    },
    count,
  });

  return (
    <div
      className={st(classes.root, { mobileView: isMobile, fluid: isEditorX })}
    >
      <ResultsStatus
        text={t(messageKey, { number: count, query: searchQuery || '' })}
      />
      {count > 0 && <SortControl {...sortProps} />}
    </div>
  );
};
