import * as React from 'react';

import { ImageCover } from '../ImageCover';
import { stripMarkTags } from '../stripMarkTags';

import { classes } from './Thumbnail.st.css';

interface IThumbnailProps {
  imageUrl?: string;
  isWide?: boolean;
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  title: string;
  url?: string;
}

interface IDataHookAttribute {
  'data-hook'?: string;
}

export const Thumbnail: React.FC<IThumbnailProps> = props => {
  const { isWide = false } = props;

  const commonProps: React.HTMLAttributes<HTMLAnchorElement | HTMLSpanElement> &
    IDataHookAttribute = {
    'aria-hidden': true,
    'data-hook': 'item-thumbnail',
    onClick: props.onClick,
    tabIndex: -1,
    title: stripMarkTags(props.title),
  };

  const image = (
    <ImageCover
      isWide={isWide}
      src={props.imageUrl}
      title={commonProps.title}
    />
  );

  if (props.url) {
    return (
      <a {...commonProps} className={classes.root} href={props.url}>
        {image}
      </a>
    );
  }

  return (
    <span {...commonProps} className={classes.root}>
      {image}
    </span>
  );
};
