import React from 'react';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';
import { Button } from 'wix-ui-tpa/Button';
import settingsParams from '../../../settingsParams';

import { classes } from './AddToCartButton.st.css';

interface AddToCartButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const AddToCartButton: React.FC<AddToCartButtonProps> = ({
  onClick,
}) => {
  const settings = useSettings();

  const label = settings.get(settingsParams.productsAddToCartButtonText);

  return (
    <Button className={classes.root} onClick={onClick} fullWidth>
      {label}
    </Button>
  );
};
