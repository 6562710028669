import * as React from 'react';

import { Pagination } from 'wix-ui-tpa/Pagination';

import { ISearchResultsProps } from './SearchResults.types';
import { SearchInput } from '../SearchInput';
import { Spinner } from '../Spinner';
import { DemoContentNotification } from '../DemoContentNotification';
import { StatusLine } from '../StatusLine';
import { TabLine } from './TabLine';
import { FailedMessage } from '../ResultsStatus';

import { st, classes } from './SearchResults.st.css';
import { useEnvironment } from '@wix/yoshi-flow-editor';

const MAX_PAGES_TO_SHOW_IN_PAGINATION = {
  DESKTOP: 5,
  MOBILE: 3,
};

export const SearchResults: React.FC<ISearchResultsProps> = ({
  buildPageUrl,
  failed,
  isDemoContent = false,
  isLoading = false,
  isPaginationHidden,
  isSearchBarEnabled,
  sortProps,
  tabsAlignment,
  totalCount,
  visibleTabsWithTitles,
  apiErrorDetails,
  ...props
}) => {
  const { isMobile, isEditorX } = useEnvironment();
  return (
    <div
      data-hook="search-results-root"
      className={st(classes.root, { mobileView: isMobile, fluid: isEditorX })}
    >
      {isSearchBarEnabled && (
        <SearchInput
          clearLabel={props.searchClearLabel}
          onChange={props.onQueryChange}
          onClear={props.onQueryClear}
          onSubmit={props.onSubmit}
          placeholder={props.searchPlaceholder}
          value={props.searchQuery}
        />
      )}
      <div data-hook="content-wrapper" className={classes.contentWrapper}>
        <div
          data-hook="content"
          className={st(classes.content, { loading: isLoading })}
        >
          {visibleTabsWithTitles.length > 1 && (
            <TabLine
              activeTabIndex={props.activeTabIndex}
              alignment={tabsAlignment}
              items={visibleTabsWithTitles}
              onTabClick={props.onTabChange}
            />
          )}

          {!failed && (
            <StatusLine
              activeTabIndex={props.activeTabIndex}
              searchQuery={props.lastQuery}
              settings={props.settings}
              sortProps={sortProps}
              visibleTabsWithTitles={visibleTabsWithTitles}
            />
          )}

          {failed && (
            <FailedMessage
              isMobile={isMobile}
              apiErrorDetails={apiErrorDetails}
            />
          )}

          {isDemoContent ? (
            <DemoContentNotification text={props.demoContentNotificationText} />
          ) : null}
          {props.children}
          {props.currentPage && props.totalPages && !isPaginationHidden && (
            <div
              className={st(classes.paginationWrapper, {
                alignment: props.paginationAlignment,
              })}
            >
              <Pagination
                totalPages={props.totalPages}
                currentPage={props.currentPage}
                maxPagesToShow={
                  isMobile
                    ? MAX_PAGES_TO_SHOW_IN_PAGINATION.MOBILE
                    : MAX_PAGES_TO_SHOW_IN_PAGINATION.DESKTOP
                }
                pageUrl={buildPageUrl}
                onChange={props.onPageChange}
                data-hook="pagination"
              />
            </div>
          )}
        </div>
        {isLoading && <Spinner dataHook="spinner" />}
      </div>
    </div>
  );
};
